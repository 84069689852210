import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  pageTitle: any;
  constructor(private title: Title) {}

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  set(title: any): void {
    this.pageTitle = title;
  }

  get(): any {
    return this.pageTitle;
  }
}
